<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col cols="6" class="pb-1 pt-0">
          <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                {{ item.text.toUpperCase() }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
        <v-col cols="6" class="pb-1 pt-0 h-100">
          <office-login></office-login>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="10" class="pt-1">
          <span class="headline font-weight-medium">Almacenes</span>
          <br />
          <storehouse-breadcrumb></storehouse-breadcrumb>
        </v-col>
        <v-col cols="2" class="pt-1">
          <v-btn
            v-if="this.$hasPermision('almacenes.principal')"
            dark
            color="#023145"
            width="140"
            class="float-right"
            @click="handleMainStore"
          >
            Principal
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4" class="pt-1">
          <v-text-field
            v-model="search_inventory"
            label="Buscar"
            hide-details
            prepend-inner-icon="mdi-magnify"
            solo
            @keydown="handleInputSearchProduct"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3" class="pt-1">
          <v-btn class="w-btn-search" height="40" @click="handleSearch">
            Buscar
          </v-btn>
        </v-col>
        <v-col cols="5" class="pt-1">
          <v-btn
            dark
            class="float-right ml-2"
            color="green"
            @click="handleViewDownload"
          >
            Descargar XLS
          </v-btn>
          <v-btn
            v-if="this.$hasPermision('almacenes.filter')"
            dark
            color="#023145"
            outlined
            width="140"
            class="float-right"
            @click="handleShowFilters"
          >
            <v-icon class="mr-2" size="14">fas fa-sort-amount-down</v-icon>
            Ver Filtros
          </v-btn>

          <v-menu rounded="0" offset-y>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                color="blue"
                class="white--text float-right mr-2"
                v-bind="attrs"
                v-on="on"
                :disabled="showButtonSave"
              >
                Guardar Movimiento
              </v-btn>
            </template>

            <v-list>
              <v-list-item link>
                <v-list-item-title @click="handleSaveNewMovement('new_stock')"
                  >Nuevo Stock
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item link>
                <v-list-item-title
                  @click="handleSaveNewMovement('increase_stock')"
                  >Aumentar Stock
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item link>
                <v-list-item-title
                  @click="handleSaveNewMovement('domestic_consumption')"
                  >Consumo Interno
                </v-list-item-title>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item link>
                <v-list-item-title @click="handleSaveNewMovement('decrease')"
                  >Merma
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!--
                    <v-btn
                        :disabled="showButtonSave"
                        dark
                        class="float-right mr-2"
                        color="blue"
                        @click="handleSaveNewStock">
                        Guardar Movimiento
                    </v-btn>
                    -->
        </v-col>
      </v-row>

      <v-row v-if="main_storehouse == true">
        <v-col cols="12" class="pt-1 pb-1">
          <v-card>
            <v-data-table
              v-model="selectedRows"
              :headers="headers_main"
              :items="inventories"
              :items-per-page="10"
              class="elevation-1 w-table"
              height="50vh"
              :single-select="true"
              :loading="loading"
              :options.sync="options"
              :server-items-length="paginated.total"
              loading-text="Loading... Please wait"
            >
              >
              <template v-slot:top>
                <span
                  class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table"
                >
                  Inventario
                </span>
                <v-divider></v-divider>
              </template>

              <template v-slot:item.description="{ item }">
                <span>
                  {{ item.description }}
                </span>
              </template>

              <template v-slot:item.category_name="{ item }">
                <span>
                  {{ item.category_name }}
                </span>
              </template>

              <template v-slot:item.stock="{ item }">
                <span
                  v-if="
                    item.stock &&
                      parseFloat(item.stock) > parseFloat(item.minimum_stock)
                  "
                >
                  {{ item.stock }}
                </span>
                <span
                  v-else-if="
                    item.stock &&
                      parseFloat(item.stock) <= parseFloat(item.minimum_stock)
                  "
                  class="label-minimum_stock"
                >
                  <v-icon color="red" class="mr-2" size="16">
                    fas fa-exclamation-triangle
                  </v-icon>
                  {{ item.stock }}
                </span>
                <span v-else class="label-minimum_stock">
                  <v-icon color="red" class="mr-2" size="16">
                    fas fa-exclamation-triangle
                  </v-icon>
                  0.00
                </span>
              </template>

              <template v-slot:item.cost="{ item }">
                <span v-if="item.cost">
                  {{ (item.stock * parseFloat(item.cost)) | currency("S/") }}
                </span>
              </template>

              <template v-slot:item.new_stock="{ item }">
                <v-text-field
                  :value="item.new_stock"
                  type="number"
                  outlined
                  dense
                  hide-details
                  class="input-stock float-right"
                  @focus="handleFocusNewStock(item)"
                  @input="handleInputNewStock"
                ></v-text-field>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  v-if="item.id && verifyPermission('almacenes.kardex')"
                  dark
                  color="blue"
                  small
                  class="mr-3"
                  @click="handleViewKardex(item)"
                >
                  Kardex
                </v-btn>

                <v-btn
                  v-if="verifyPermission('almacenes.kardex')"
                  dark
                  color="grey"
                  small
                  @click="handleViewAvailability(item)"
                  class="mr-3"
                >
                  Ver stock
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12" class="pt-1 pb-1">
          <v-card>
            <v-data-table
              v-model="selectedRows"
              :headers="headers_office"
              :items="inventories"
              :items-per-page="10"
              class="elevation-1 w-table"
              height="50vh"
              :single-select="true"
              :loading="loading"
              :options.sync="options"
              :server-items-length="paginated.total"
              loading-text="Loading... Please wait"
            >
              >
              <template v-slot:top>
                <span
                  class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table"
                >
                  Inventario
                </span>
                <v-divider></v-divider>
              </template>

              <template v-slot:item.description="{ item }">
                <span>
                  {{ item.description }}
                </span>
              </template>

              <template v-slot:item.category_name="{ item }">
                <span>
                  {{ item.category_name }}
                </span>
              </template>

              <template v-slot:item.stock="{ item }">
                <span
                  v-if="
                    item.stock &&
                      parseFloat(item.stock) > parseFloat(item.minimum_stock)
                  "
                >
                  {{ item.stock }}
                </span>
                <span
                  v-else-if="
                    item.stock &&
                      parseFloat(item.stock) <= parseFloat(item.minimum_stock)
                  "
                  class="label-minimum_stock"
                >
                  <v-icon color="red" class="mr-2" size="16">
                    fas fa-exclamation-triangle
                  </v-icon>
                  {{ item.stock }}
                </span>
                <span v-else class="label-minimum_stock">
                  <v-icon color="red" class="mr-2" size="16">
                    fas fa-exclamation-triangle
                  </v-icon>
                  0.00
                </span>
              </template>

              <template v-slot:item.cost="{ item }">
                <span v-if="item.cost">
                  {{ (item.stock * parseFloat(item.cost)) | currency("S/") }}
                </span>
              </template>

              <template v-slot:item.new_stock="{ item }">
                <v-text-field
                  :value="item.new_stock"
                  type="number"
                  outlined
                  dense
                  hide-details
                  class="input-stock float-right"
                  @focus="handleFocusNewStock(item)"
                  @input="handleInputNewStock"
                ></v-text-field>
                <!--
                                <v-text-field
                                    v-if="(office_id === office_filtered || office_filtered == null) && new_stocks[getIndex(item.product_id)]"
                                    type="number"
                                    v-model="new_stocks[getIndex(item.product_id)].new_stock"
                                    outlined
                                    dense
                                    hide-details
                                ></v-text-field>
                                -->
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn
                  v-if="item.id && verifyPermission('almacenes.kardex')"
                  dark
                  color="blue"
                  small
                  class="mr-3"
                  @click="handleViewKardex(item)"
                >
                  Kardex
                </v-btn>

                <v-btn
                  v-if="
                    viewButtonConvert() && verifyPermission('almacenes.kardex')
                  "
                  dark
                  color="green"
                  small
                  @click="handleViewConvertProduct(item)"
                  class="mr-3"
                >
                  Convertir
                </v-btn>

                <v-btn
                  v-if="verifyPermission('almacenes.kardex')"
                  dark
                  color="grey"
                  small
                  @click="handleViewAvailability(item)"
                  class="mr-3"
                >
                  Ver stock
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <FiltersPaginated ref="filters"></FiltersPaginated>

    <stock-stores ref="stockStores"></stock-stores>

    <convert-product ref="convertProduct"></convert-product>

    <download-inventory ref="downloadInventory"></download-inventory>

    <areas-dialog ref="areasDialog"></areas-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

import router from "@/router/index";
import OfficeLogin from "../../../components/auth/OfficeLogin";
import ArrayTools from "../../../helpers/ArrayTools";
import FiltersPaginated from "../../../components/storehouses/FiltersPaginated";
import StorehouseBreadcrumb from "../../../components/storehouses/StorehouseBreadcrumb";
import StockStores from "../../../components/storehouses/StockStores";
import ConvertProduct from "../../../components/storehouses/ConvertProduct";
import DownloadInventory from "../../../components/storehouses/DownloadInventory";
import AreasDialog from "../../../components/storehouses/AreasDialog";

export default {
  name: "Storehouses",
  components: {
    FiltersPaginated,
    StorehouseBreadcrumb,
    StockStores,
    ConvertProduct,
    OfficeLogin,
    DownloadInventory,
    AreasDialog,
  },
  watch: {
    options: {
      handler() {
        this.loading = true;

        this.perPage = this.options.itemsPerPage;
        this.pageSelected = this.options.page;
        const search = this.search_inventory;
        if (
          this.filtersPaginated.main_storehouse != null ||
          this.filtersPaginated.office_id != null ||
          this.filtersPaginated.category_id != null ||
          this.filtersPaginated.value_stock != null
        ) {
          this.getList({
            paginated: true,
            page: this.pageSelected,
            itemsPerPage: this.perPage,
            main_storehouse: this.filtersPaginated.main_storehouse,
            office_id: this.filtersPaginated.office_id,
            category_id: this.filtersPaginated.category_id,
            value_stock: this.filtersPaginated.value_stock,
          })
            .then((res) => (this.loading = false))
            .catch((err) => (this.loading = false));
        } else {
          if (search != null && search.trim().length > 0) {
            // this.perPage = 1
            // getList({office_id:this.office_id,paginated:true})

            this.getList({
              main_storehouse: this.main_storehouse,
              office_id: this.office_id,
              paginated: true,
              page: this.pageSelected,
              itemsPerPage: this.perPage,
              description: search,
            })
              .then((res) => (this.loading = false))
              .catch((err) => (this.loading = false));
          } else {
            this.getList({
              office_id: this.office_id,
              paginated: true,
              page: this.pageSelected,
              itemsPerPage: this.perPage,
            })
              .then((res) => (this.loading = false))
              .catch((err) => (this.loading = false));
          }
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      itemsBreadcrumbs: [
        {
          text: "Logística",
          disabled: true,
          href: "",
        },
      ],
      companies: [],
      value_company: null,
      disabled_company: false,
      sedes: [],
      value_sede: null,
      disabled_sede: false,
      offices: [],
      value_office: null,
      headers: [],
      headers_office: [
        {
          text: "DESCRIPCIÓN",
          value: "description",
          sortable: false,
          width: 450,
        },
        { text: "CATEGORIA", value: "category_name", sortable: false },
        { text: "UND. DE MEDIDA", value: "unit_name", sortable: false },
        { text: "STOCK", value: "stock", align: "end", sortable: false },
        { text: "VALORIZADO", value: "cost", align: "end", sortable: false },
        {
          text: "CANT. MOVIMIENTO",
          value: "new_stock",
          align: "center",
          width: 130,
          sortable: false,
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      headers_main: [
        {
          text: "DESCRIPCIÓN",
          value: "description",
          sortable: false,
          width: 450,
        },
        { text: "CATEGORIA", value: "category_name", sortable: false },
        { text: "UND. DE MEDIDA", value: "unit_name", sortable: false },
        { text: "STOCK", value: "stock", align: "end", sortable: false },
        { text: "VALORIZADO", value: "cost", align: "end", sortable: false },
        {
          text: "CANT. MOVIMIENTO",
          value: "new_stock",
          align: "center",
          width: 130,
          sortable: false,
        },
        { text: "", value: "actions", align: "end" },
      ],
      products: [],
      selectedRows: [],
      search_inventory: null,
      new_stocks: [],
      loading: false,
      dataTable: { page: 1 },
      options: {},
      pageSelected: 1,
      perPage: 10,
    };
  },
  mounted() {
    this.changeFilterPaginated({
      category_id: null,
      itemsPerPage: null,
      main_storehouse: null,
      office_id: null,
      paginated: true,
      value_stock: null,
    });
  },
  computed: {
    ...mapState("authentication", [
      "select_office",
      "user_companies",
      "user_sedes",
      "user_offices",
      "company_id",
      "office_id",
    ]),
    ...mapState("inventories", [
      "inventories",
      "main_storehouse",
      "office_name",
      "sede_name",
      "company_name",
      "stores",
      "office_filtered",
      "value_stock",
      "inventory_selected",
      "paginated",
      "filtersPaginated",
    ]),
    showButtonSave() {
      console.log("REGISTRANDO BUTTON");
      let hideButton = true;
      let numberItemsModified = 0;
      console.log(this.inventories);
      this.inventories.forEach((element) => {
        if (parseFloat(element.new_stock) > 0) {
          numberItemsModified++;
        }
      });
      if (numberItemsModified > 0) {
        hideButton = false;
      }
      return hideButton;
    },
  },
  methods: {
    ...mapActions("inventories", [
      "clean_data",
      "getInventories",
      "run_select_inventory",
      "run_set_main_storehouse",
      "run_set_office_id",
      "getList",
      "getAvailability",
      "selectOfficeFiltered",
      "storeNewStock",
      "setInventories",
      "storeDomesticConsumption",
      "storeDecrease",
      "setInventorySelected",
      "replaceInventoryItem",
      "increaseStock",
      "changeFilterPaginated",
    ]),
    ...mapActions("imeis", ["searchImei"]),
    ...mapActions("sedes", ["getSedes"]),
    ...mapGetters("authentication", ["isSuperadmin"]),
    ...mapActions("offices", ["getOffices"]),
    ...mapActions("categories", ["getCategories"]),
    ...mapMutations("inventories", ["set_value_stock"]),
    customText(item) {
      let text = item.name;

      return text;
    },
    handleViewKardex(item) {
      this.run_select_inventory(item);
      router.push("/app/logistica/kardex");
    },
    async handleSearch() {
      // let filters = {
      //   main_storehouse: this.main_storehouse,
      //   office_id: this.office_filtered,
      //   description: this.search_inventory,
      // };
      this.pageSelected = 1;
      let filters = {
        main_storehouse: this.main_storehouse,
        office_id: this.office_id,
        description: this.search_inventory,
        paginated: true,
        page: this.pageSelected,
      };
      this.changeFilterPaginated({
        category_id: null,
        itemsPerPage: null,
        main_storehouse: null,
        office_id: null,
        paginated: null,
        value_stock: null,
      });

      this.loading = true;
      await this.getList(filters)
        .catch((err) => (this.loading = false))
        .then((res) => (this.loading = false));
    },
    handleShowFilters() {
      this.$refs.filters.showForm("Filtrar datos de almacenes");
    },
    async handleMainStore() {
      this.run_set_main_storehouse(true);
      this.run_set_office_id(null);
      this.selectOfficeFiltered(null);
      let filters = {
        main_storehouse: this.main_storehouse,
        paginated: true,
        itemsPerPage: 10,
      };
      this.loading = true;
      await this.getList(filters)
        .then((res) => (this.loading = false))
        .catch((err) => (this.loading = false));
    },
    async getAllInventories() {
      // await this.getList();
      this.loading = true;
      await this.getList({ office_id: this.office_id, paginated: true })
        .then((res) => (this.loading = false))
        .catch((err) => (this.loading = false));
    },
    verifyPermission(permission) {
      return this.$hasPermision(permission);
    },
    handleInputSearchProduct(KeyboardEvent) {
      if (KeyboardEvent.code == "Enter") {
        this.handleSearch();
      }
    },
    verifyStore() {
      let canShowCost = false;
      if (this.main_storehouse === true) {
        canShowCost = false;
      } else if (
        this.office_id === this.office_filtered ||
        this.office_filtered == null
      ) {
        canShowCost = true;
      }

      return canShowCost;
    },
    async handleViewAvailability(item) {
      this.run_select_inventory(item);
      await this.getAvailability(item.product_id);
      this.$refs.stockStores.show();
    },
    handleViewConvertProduct(item) {
      this.run_select_inventory(item);
      this.$refs.convertProduct.show();
    },
    viewButtonConvert() {
      let viewButton = false;
      if (this.main_storehouse === true) {
        viewButton = false;
      } else if (
        this.office_id === this.office_filtered ||
        this.office_filtered == null
      ) {
        viewButton = true;
      }

      return viewButton;
    },
    getIndex(product_id) {
      return this.new_stocks.findIndex((i) => i.product_id === product_id);
    },
    async handleSaveNewMovement(type) {
      let new_inventory = [];
      this.inventories.forEach((element) => {
        if (parseFloat(element.new_stock) > 0) {
          let today = new Date();
          let item = {
            id: today.getTime(),
            inventory_id: element.id,
            product_id: element.product_id,
            new_stock: element.new_stock,
          };
          new_inventory.push(item);
        }
      });
      let office = this.office_id;
      if (this.office_filtered) {
        office = this.office_filtered;
      }
      let data = {
        main_storehouse: this.main_storehouse,
        office_id: office,
        new_stocks: new_inventory,
      };
      if (type == "new_stock") {
        await this.storeNewStock(data);
      } else if (type == "domestic_consumption") {
        let formData = Object.assign({}, data);
        this.$refs.areasDialog.show(formData);
        //await this.storeDomesticConsumption(data);
      } else if (type == "decrease") {
        await this.storeDecrease(data);
      } else if (type == "increase_stock") {
        await this.increaseStock(data);
      }
    },
    async loadData() {
      //this.set_value_stock('Con Stock');
      this.headers = this.headers_main;
      this.getOffices({
        company_id: this.company_id,
      });
      this.clean_data();
      this.getCategories();
      // await this.getAllInventories();
    },

    handleViewDownload() {
      this.$refs.downloadInventory.showForm("Descargar reporte de inventario");
    },
    handleFocusNewStock(inventory) {
      this.setInventorySelected(inventory);
    },
    handleInputNewStock(new_stock) {
      let inventory = Object.assign({}, this.inventory_selected);
      if (!isNaN(new_stock) && new_stock !== null && new_stock !== "") {
        new_stock = parseFloat(new_stock);
      }
      inventory.new_stock = new_stock;
      this.updateRow(inventory);
    },
    updateRow(item) {
      let index = this.findIndexFromItem(this.inventories, item.product_id);
      if (index > -1) {
        this.replaceInventoryItem({
          index: index,
          item: item,
        });
      }
    },
    findIndexFromItem(data, product_id) {
      let indexFounded = -1;
      data.forEach((element, index) => {
        if (element.product_id == product_id) {
          indexFounded = index;
        }
      });
      return indexFounded;
    },
  },
  created() {
    this.loadData();
  },
  destroyed() {
    this.setInventories([]);
  },
};
</script>

<style scoped>
.label-minimum_stock {
  color: #f44336;
  font-weight: bold;
}
</style>
